import {
  chromeStateVar,
  cookieSettingStateVar,
  helpStateVar,
  systemMessageVar,
  systemMessageClosedBannersVar,
  systemMsgRefetchFunctionVar,
  userPreferenceStateVar,
  appTenantRefetchFunctionVar,
  tenantAppRefetchFunctionVar,
  userPrefRefetchFunctionVar,
  notificationUnreadCount,
  userInfo,
} from '../apollo/cache';

const routeToAppCodeFunc = (path, applicationData) => {
  let appCode = '';
  let subRoute = '';
  // path format: /{appRoute}/{subRoute}
  const appRoute = path.split('/')[1];
  const app = Object.entries(applicationData).find(([, { route }]) => route === appRoute);
  if (app) {
    [appCode] = app;
    subRoute = path.substring(appRoute.length + 1);
  }
  return { appCode, subRoute };
};

export default routeToAppCodeFunc;

export const toggleHelp = (state) => helpStateVar(state);
export const toggleChrome = (state) => chromeStateVar(state);
export const toggleCookieSettings = (state) => cookieSettingStateVar(state);
export const toggleSystemMessages = (state) => systemMessageVar(state);
export const toggleSetClosedSystemMessages = (state) => systemMessageClosedBannersVar(state);
export const toggleUserPreferenceData = (state) => userPreferenceStateVar(state);

export const toggleSystemMessageRefetchFunction = (state) => systemMsgRefetchFunctionVar(state);
export const toggleAppTenantRefetchFunction = (state) => appTenantRefetchFunctionVar(state);
export const toggleTenantAppRefetchFunction = (state) => tenantAppRefetchFunctionVar(state);
export const toggleUserPreferenceRefetchFunction = (state) => userPrefRefetchFunctionVar(state);

export const toggleNotificationUnreadCount = (state) => notificationUnreadCount(state);
export const toggleUserInfo = (state) => userInfo(state);

export const flags = {
  en_GB: 'English (UK)',
  fr_FR: 'Français',
  zh_Hant: '繁體中文',
  zh_Hans: '简体中文',
  es_ES: 'Español',
  de_DE: 'Deutsche',
  it_IT: 'Italiano',
};

// penpal methods
const getParsedToken = () => {
  const oktaTokens = localStorage.getItem('okta-token-storage');
  try {
    const tokens = JSON.parse(oktaTokens);
    return tokens;
  } catch (error) {
    return false;
  }
};

export const getUserId = () => {
  const { idToken: { claims: { sub } = {} } = {} } = getParsedToken();
  return {
    userId: sub,
  };
};

export const getUserType = () => {
  const { accessToken: { claims: { UserType } = {} } = {} } = getParsedToken();
  return {
    userType: UserType,
  };
};

export const manageToken = (token) => {
  let managedTokens = localStorage.getItem('managed-tokens');
  if (managedTokens) {
    managedTokens = JSON.parse(managedTokens);
  } else {
    managedTokens = [];
  }
  managedTokens = managedTokens.filter(({ accessToken }) => {
    const tokenExpiry = accessToken?.expiresAt;
    const currentTime = new Date().getTime() / 1000;
    return tokenExpiry > currentTime;
  });
  managedTokens.push(token);
  managedTokens = [...new Set(managedTokens)]; // removes duplicates
  localStorage.setItem('managed-tokens', JSON.stringify(managedTokens));
};

function isObject(object) {
  return object != null && typeof object === 'object';
}

export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects
      && (!deepEqual(val1, val2) || !areObjects)
      && val1 !== val2
    ) {
      return false;
    }
  }
  return true;
};

export const getFormattedLocale = (localeWithUnderscore) => (
  localeWithUnderscore.replace('_', '-')
);
