import React from 'react';
import PropTypes from 'prop-types';
import { TagManager } from '@dentsu-ui/components';

import config from './config';

export default Object.assign((props) => {
  // TagManger is rendering under Authentication component for getting user_id and user_type
  const { authConfig, children } = props;
  const { performance = false, essential = true } = JSON.parse(localStorage.getItem('cookie_policy')) || {};

  if (authConfig) {
    const gtmParams = {
      id: config.tagManagerConfigs.gtm_id,
      // Optional environment parameters for D-Int/INT/NFT/STG/UAT
      ...(config && config.nodeEnv && !config.nodeEnv.includes('prod') && {
        environment: {
          gtm_auth: config.tagManagerConfigs.gtm_auth,
          gtm_preview: config.tagManagerConfigs.gtm_preview,
        },
      }),
      dataLayer: {
        event: config.tagManagerConfigs.event,
        user_id: authConfig.userId,
        user_type: authConfig.userType,
        essential,
        performance,
      },
    };

    return (
      <TagManager params={gtmParams}>
        {children}
      </TagManager>
    );
  }

  return children;
}, {
  propTypes: {
    children: PropTypes.element.isRequired,
    authConfig: PropTypes.objectOf.isRequired,
  },
});
