import React, {
  lazy, Suspense,
} from 'react';

import { ThemeProvider } from 'styled-components';
import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';

import { Loading } from '@dentsu-ui/components';
import config from './config';
import Authentication from './components/Authentication';

const ProtectedRoute = lazy(() => import('./ProtectedRoute' /* webpackChunkName: "ProtectedRoute" */));

window.addEventListener('storage', (e) => {
  if (
    e.key === 'isAuthenticated'
    && e.oldValue === 'true'
    && e.newValue === 'false'
  ) {
    window.location.reload();
  }
});

const oktaAuth = new OktaAuth({
  issuer: config.okta.issuer,
  clientId: config.okta.client_id,
  redirectUri: config.okta.redirect_uri,
  tokenManager: {
    expireEarlySeconds: 120,
  },
});

const App = () => (
  <ThemeProvider
    theme={{
      breakpoints: ['64em', '80em', '90em', '120em'],
    }}
  >
    <Router>
      <Security oktaAuth={oktaAuth}>
        <Switch>
          <Route path="/implicit/callback">
            <LoginCallback />
          </Route>
          <SecureRoute path="/">
            <Authentication>
              <Suspense fallback={<Loading />}>
                <ProtectedRoute />
              </Suspense>
            </Authentication>
          </SecureRoute>
        </Switch>
      </Security>
    </Router>
  </ThemeProvider>
);

export default App;
