import { useReactiveVar } from '@apollo/client';
import { makeVar } from '@apollo/client/cache';

export const applicationsListVar = makeVar({});
export const appVar = makeVar({});
export const helpStateVar = makeVar(false);
export const chromeStateVar = makeVar(true);
export const cookieSettingStateVar = makeVar({
  isOpen: false,
  tabNo: 0,
  content: {},
});
export const cookiesStateVar = makeVar({
  content: [],
  preferenceType: '',
  preferenceDate: '',
  contentId: '',
  message: '',
});
export const cookieMutation = makeVar(false);
export const systemMessageVar = makeVar({ totalCount: 0, records: [] });
export const systemMessageClosedBannersVar = makeVar([]);
export const myClientPaginationMatrix = makeVar({
  /*
   This is a matrix for the My Clients tab.
   IMP: (row * column) should be less than 50.

   row(3) * column(4) = default(12)
   12 is the default pagination in My Clients tab.
  */
  row: 3,
  column: 4,
});

export const userPreferenceStateVar = makeVar();

export const systemMsgRefetchFunctionVar = makeVar();
export const appTenantRefetchFunctionVar = makeVar();
export const tenantAppRefetchFunctionVar = makeVar();
export const userPrefRefetchFunctionVar = makeVar();
export const notificationUnreadCount = makeVar();

export const userInfo = makeVar({
  name: '',
  email: '',
});

export const useApplicationsListVar = () => useReactiveVar(applicationsListVar);
export const useAppVar = () => useReactiveVar(appVar);
export const useHelpStateVar = () => useReactiveVar(helpStateVar);
export const useChromeStateVar = () => useReactiveVar(chromeStateVar);
export const useCookieSettingStateVar = () => useReactiveVar(cookieSettingStateVar);
export const useCookiesStateVar = () => useReactiveVar(cookiesStateVar);
export const useCookieMutationLoading = () => useReactiveVar(cookieMutation);
export const useSystemMessageStateVar = () => useReactiveVar(systemMessageVar);
export const useClosedBannersVar = () => useReactiveVar(systemMessageClosedBannersVar);
export const useMyClientPaginationMatrix = () => useReactiveVar(myClientPaginationMatrix);
export const useUserPreferenceVar = () => useReactiveVar(userPreferenceStateVar);

export const useSystemMessageRefetchFunction = () => useReactiveVar(systemMsgRefetchFunctionVar);
export const useAppTenantRefetchFunction = () => useReactiveVar(appTenantRefetchFunctionVar);
export const useTenantAppRefetchFunction = () => useReactiveVar(tenantAppRefetchFunctionVar);
export const userPreferenceRefetchFunction = () => useReactiveVar(userPrefRefetchFunctionVar);
export const useNotificationUnreadCount = () => useReactiveVar(notificationUnreadCount);

export const useUserInfo = () => useReactiveVar(userInfo);
