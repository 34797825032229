export default async (instrumentationKey) => {
  if (instrumentationKey) {
    const { ApplicationInsights } = await import(
      '@microsoft/applicationinsights-web'
    );
    const appInsights = new ApplicationInsights({
      config: { instrumentationKey /* ...Other Configuration Options... */ },
    });
    appInsights.loadAppInsights();
    // Manually call trackPageView to establish the current user/session/pageview
    appInsights.trackPageView({ name: 'DAN | Landing Page' });
  }
};
