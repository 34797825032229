// import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { disableReactDevTools } from './libs/disable-react-devtools';
import App from './app';

import '@dentsu-ui/components/styles.css';

import reportWebVitals from './reportWebVitals';
import config from './config';
import invokeSre from './SRIntegration';

invokeSre(config.instrumentationKey);

const rootElement = document.getElementById('root');

// re-build - 08

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement,
  );
};

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line
reportWebVitals(console.info);

render();
